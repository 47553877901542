define("discourse/plugins/chat/discourse/components/chat-footer", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/chat/footer/unread-indicator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _dButton, _concatClass, _i18n, _unreadIndicator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _ChatFooter;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatFooter = _exports.default = (_class = (_ChatFooter = class ChatFooter extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor5, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor6, this);
    }
    get includeThreads() {
      if (!this.siteSettings.chat_threads_enabled) {
        return false;
      }
      return this.chatChannelsManager.hasThreadedChannels;
    }
    get directMessagesEnabled() {
      return this.chat.userCanAccessDirectMessages;
    }
    get shouldRenderFooter() {
      return this.chatStateManager.hasPreloadedChannels && (this.includeThreads || this.directMessagesEnabled);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.shouldRenderFooter}}
        <nav class="c-footer">
          <DButton
            @route="chat.channels"
            @icon="comments"
            @translatedLabel={{i18n "chat.channel_list.title"}}
            aria-label={{i18n "chat.channel_list.aria_label"}}
            id="c-footer-channels"
            class={{concatClass
              "btn-flat"
              "c-footer__item"
              (if (eq this.router.currentRouteName "chat.channels") "--active")
            }}
          >
            <UnreadChannelsIndicator />
          </DButton>
  
          {{#if this.directMessagesEnabled}}
            <DButton
              @route="chat.direct-messages"
              @icon="users"
              @translatedLabel={{i18n "chat.direct_messages.title"}}
              aria-label={{i18n "chat.direct_messages.aria_label"}}
              id="c-footer-direct-messages"
              class={{concatClass
                "btn-flat"
                "c-footer__item"
                (if
                  (eq this.router.currentRouteName "chat.direct-messages")
                  "--active"
                )
              }}
            >
              <UnreadDirectMessagesIndicator />
            </DButton>
          {{/if}}
  
          {{#if this.includeThreads}}
            <DButton
              @route="chat.threads"
              @icon="discourse-threads"
              @translatedLabel={{i18n "chat.my_threads.title"}}
              aria-label={{i18n "chat.my_threads.aria_label"}}
              id="c-footer-threads"
              class={{concatClass
                "btn-flat"
                "c-footer__item"
                (if (eq this.router.currentRouteName "chat.threads") "--active")
              }}
            >
              <UnreadThreadsIndicator />
            </DButton>
          {{/if}}
        </nav>
      {{/if}}
    
  */
  {
    "id": "sERaRX8g",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRenderFooter\"]],[[[1,\"      \"],[10,\"nav\"],[14,0,\"c-footer\"],[12],[1,\"\\n        \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.channel_list.aria_label\"],null]],[24,1,\"c-footer-channels\"],[16,0,[28,[32,2],[\"btn-flat\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"router\",\"currentRouteName\"]],\"chat.channels\"],null],\"--active\"]],null]]],[[\"@route\",\"@icon\",\"@translatedLabel\"],[\"chat.channels\",\"comments\",[28,[32,1],[\"chat.channel_list.title\"],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,4],null,null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"directMessagesEnabled\"]],[[[1,\"          \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.direct_messages.aria_label\"],null]],[24,1,\"c-footer-direct-messages\"],[16,0,[28,[32,2],[\"btn-flat\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"router\",\"currentRouteName\"]],\"chat.direct-messages\"],null],\"--active\"]],null]]],[[\"@route\",\"@icon\",\"@translatedLabel\"],[\"chat.direct-messages\",\"users\",[28,[32,1],[\"chat.direct_messages.title\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,5],null,null,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"includeThreads\"]],[[[1,\"          \"],[8,[32,0],[[16,\"aria-label\",[28,[32,1],[\"chat.my_threads.aria_label\"],null]],[24,1,\"c-footer-threads\"],[16,0,[28,[32,2],[\"btn-flat\",\"c-footer__item\",[52,[28,[32,3],[[30,0,[\"router\",\"currentRouteName\"]],\"chat.threads\"],null],\"--active\"]],null]]],[[\"@route\",\"@icon\",\"@translatedLabel\"],[\"chat.threads\",\"discourse-threads\",[28,[32,1],[\"chat.my_threads.title\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,6],null,null,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-footer.js",
    "scope": () => [_dButton.default, _i18n.default, _concatClass.default, _truthHelpers.eq, _unreadIndicator.UnreadChannelsIndicator, _unreadIndicator.UnreadDirectMessagesIndicator, _unreadIndicator.UnreadThreadsIndicator],
    "isStrictMode": true
  }), _ChatFooter), _ChatFooter), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});