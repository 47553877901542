define("discourse/plugins/chat/discourse/components/chat/modal/delete-channel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/text-field", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-common/lib/later", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template2, _utils, _dButton, _dModal, _textField, _ajaxError, _i18n, _later, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _ChatModalDeleteChannel;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatModalDeleteChannel = _exports.default = (_class = (_ChatModalDeleteChannel = class ChatModalDeleteChannel extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "channelNameConfirmation", _descriptor3, this);
      _initializerDefineProperty(this, "deleting", _descriptor4, this);
      _initializerDefineProperty(this, "confirmed", _descriptor5, this);
      _initializerDefineProperty(this, "flash", _descriptor6, this);
      _initializerDefineProperty(this, "flashType", _descriptor7, this);
    }
    get channel() {
      return this.args.model.channel;
    }
    get buttonDisabled() {
      if (this.deleting || this.confirmed) {
        return true;
      }
      if ((0, _utils.isEmpty)(this.channelNameConfirmation) || this.channelNameConfirmation.toLowerCase() !== this.channel.title.toLowerCase()) {
        return true;
      }
      return false;
    }
    get instructionsText() {
      return (0, _template2.htmlSafe)(_discourseI18n.default.t("chat.channel_delete.instructions", {
        name: this.channel.escapedTitle
      }));
    }
    deleteChannel() {
      this.deleting = true;
      return this.chatApi.destroyChannel(this.channel.id, this.channelNameConfirmation).then(() => {
        this.confirmed = true;
        this.flash = _discourseI18n.default.t("chat.channel_delete.process_started");
        this.flashType = "success";
        (0, _later.default)(() => {
          this.args.closeModal();
          this.router.transitionTo("chat");
        }, 3000);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.deleting = false);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @closeModal={{@closeModal}}
        class="chat-modal-delete-channel"
        @inline={{@inline}}
        @title={{i18n "chat.channel_delete.title"}}
        @flash={{this.flash}}
        @flashType={{this.flashType}}
      >
        <:body>
          <p class="chat-modal-delete-channel__instructions">
            {{this.instructionsText}}
          </p>
  
          <TextField
            @value={{this.channelNameConfirmation}}
            @id="channel-delete-confirm-name"
            @placeholderKey="chat.channel_delete.confirm_channel_name"
            @autocorrect="off"
            @autocapitalize="off"
          />
        </:body>
        <:footer>
          <DButton
            @disabled={{this.buttonDisabled}}
            @action={{this.deleteChannel}}
            @label="chat.channel_delete.confirm"
            id="chat-confirm-delete-channel"
            class="btn-danger"
          />
          <DButton @label="cancel" @action={{@closeModal}} class="btn-flat" />
        </:footer>
      </DModal>
    
  */
  {
    "id": "Oj2Ntn8W",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-delete-channel\"]],[[\"@closeModal\",\"@inline\",\"@title\",\"@flash\",\"@flashType\"],[[30,1],[30,2],[28,[32,1],[\"chat.channel_delete.title\"],null],[30,0,[\"flash\"]],[30,0,[\"flashType\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[14,0,\"chat-modal-delete-channel__instructions\"],[12],[1,\"\\n          \"],[1,[30,0,[\"instructionsText\"]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@value\",\"@id\",\"@placeholderKey\",\"@autocorrect\",\"@autocapitalize\"],[[30,0,[\"channelNameConfirmation\"]],\"channel-delete-confirm-name\",\"chat.channel_delete.confirm_channel_name\",\"off\",\"off\"]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,1,\"chat-confirm-delete-channel\"],[24,0,\"btn-danger\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"deleteChannel\"]],\"chat.channel_delete.confirm\"]],null],[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/delete-channel.js",
    "scope": () => [_dModal.default, _i18n.default, _textField.default, _dButton.default],
    "isStrictMode": true
  }), _ChatModalDeleteChannel), _ChatModalDeleteChannel), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "channelNameConfirmation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "deleting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "flashType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteChannel"), _class.prototype)), _class);
});